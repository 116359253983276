import React from "react";

export default () => (
  <dl>
    <dt>200</dt>
    <dd>Success</dd>
    <dt>301</dt>
    <dd>Moved</dd>
    <dt>403</dt>
    <dd>Forbidden</dd>
    <dt>404</dt>
    <dd>Not Found</dd>
  </dl>
);
